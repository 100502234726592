import React, { FC, useEffect } from 'react';

const IndexPage: FC = () => {
  useEffect(() => {
    if (window.location.pathname === '/') {
      window.location.replace(`https://azarlive.com/`);
    }
  }, []);
  return <></>;
};

export default IndexPage;
